@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
	margin: 0;
}

.App {
}

@media (max-width: 600px) {
  .App {
    
  }
}

@media (max-width: 450px) {
  .App {
    margin: 0px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
}
[data-rmiz-wrap="hidden"] {
  visibility: hidden;
}

[data-rmiz-overlay] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: background-color;
}
[data-rmiz-btn-open],
[data-rmiz-btn-close] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* reset styles */
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  font: inherit;
  color: inherit;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
[data-rmiz-btn-open] {
  cursor: zoom-in;
}
[data-rmiz-btn-close] {
  cursor: zoom-out;
}
[data-rmiz-modal-content] {
  position: absolute;
  transition-property: transform;
  transform-origin: center center;
}

button {
  outline: none;
}
